.steppers-list__step {
  @apply relative;

  .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-title {
    color: var(--secondary-500) !important;
  }

  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role='button']:hover
    .ant-steps-item-icon {
    border-color: var(--secondary-500);
    .ant-steps-icon {
      color: var(--secondary-500);
    }
  }

  .steppers-list__step-item {
    @apply relative;

    &.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: var(--secondary-500);
    }

    .ant-steps-item-tail {
      margin-left: 10px !important;
    }

    .ant-steps-item-icon {
      margin-left: 0 !important;
    }

    .ant-steps-item-content {
      @apply w-[140px] text-left;

      .ant-steps-item-title {
        @apply text-base font-medium;
      }
    }
  }
}
