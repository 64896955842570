.preview-banner {
  @apply fixed top-0 left-0 w-screen h-screen flex justify-center items-center;
  background: rgba($color: #292C31, $alpha: .68);
  z-index: 1002;

  .preview-banner-content {
    width: auto;
    max-width: 80%;
    height: 65%;

    img {
      @apply w-full h-full object-contain;
    }
  }
  .preview-banner-close {
    @apply text-white hover:cursor-pointer absolute text-xl;
    top: 13%;
    right: 5%;
  }
}