:root {
  // ===Fonts Size===
  --text-2xs: 11px;
  --text-xs: 12px;
  --text-sm: 14px;
  --text-md: 16px;
  --text-lg: 20px;
  --text-xl: 24px;
  --text-2xl: 30px;
  --text-3xl: 38px;
  --text-4xl: 46px;
  --text-5xl: 56px;

  // ===Colors===
  // primary
  --primary-25: #F0F6FD;
  --primary-50: #A2BAD5;
  --primary-100: #7397BF;
  --primary-200: #5C86B5;
  --primary-300: #4575AA;
  --primary-400: #2D63A0;
  --primary-500:  #165295;
  --primary-600: #144A86;
  --primary-700: #124277;
  --primary-800: #0f3968;
  --primary-900: #0D3159;
  // secondary
  --secondary-50: #AAD8D1;
  --secondary-100: #7FC4BA;
  --secondary-200: #6ABAAF;
  --secondary-300: #55B1A3;
  --secondary-400: #3FA798;
  --secondary-500:  #2A9D8C;
  --secondary-600: #268D7E;
  --secondary-700: #227E70;
  --secondary-800: #1D6E62;
  --secondary-900: #195E54;
  // green
  --green-10: #9BDEA0;
  --green-30: #51C558;
  --green-50: #06AC11;
  --green-70: #04780C;
  --green-90: #024507;
  // Blue
  --blue-1: #E6F7FF;
  --blue-2: #1890FF;
  --blue-10: #A3C1FA;
  --blue-30: #5D93F6;
  --blue-50: #1865F2;
  --blue-70: #1147A9;
  --blue-90: #0A2861;
  // Yellow
  --yellow-10: #FDE599;
  --yellow-30: #FCD24D;
  --yellow-50: #FBBE01;
  --yellow-70: #B08501;
  --yellow-90: #644C00;
  // Red
  --red-1: #FFF2E8;
  --red-2: #FA541C;
  --red-10: #EF9D99;
  --red-20: #E2544D;
  --red-50: #D60B00;
  --red-70: #960800;
  --red-90: #560400;
  // Gray
  --gray-200: #E4E7EC;
  // Typography
  --c-disabled: rgba(41, 44, 49, 0.32);
  --c-default: rgba(41, 44, 49, 0.96);
  --c-low: rgba(41, 44, 49, 0.68);
  --c-title: rgba(0, 0, 0, 0.85);
  // Gradient
  --c-g-green: linear-gradient(270.02deg, #165295 0.02%, #2A9D8C 99.99%);
  --c-g-black: linear-gradient(180deg, #101436 0%, #1D6499 100%);
  // Neutral
  --neutral-1: #F4F7FA;
  --neutral-2: #ECEFEF;
  --neutral-3: #F5F5F5;
  --neutral-5: #D9D9D9;
  --neutral-10: #F2F4F5;
  --neutral-30: #D9DDE2;
  --neutral-50: #C0C7CE;
  --neutral-70: #A7B1BA;
  --neutral-90: #8E9AA7;
  // Dark
  --dark-10: #8B959F;
  --dark-30: #6F777F;
  --dark-50: #53595F;
  --dark-70: #383C40;
  --dark-90: #1C1E20;
  // Shades
  --shades-0: #FFFFFF;
  --shades-80: #353535;
  --shades-100: #000000;
  // Others
  --magenta-1: #FFF0F6; 
  --magenta-2: #EB2F96;
  --cyan-1: #E3FCF8;
  --cyan-2: #87E8DE;
  --orange-1: #FFF7E6;
  --orange-2: #F2BE68;
  --purple-1: #F9F0FF;
  --purple-2: #722ED1;
  --sunrise-yellow-6: #FADB14;
  --c-line: #E5E7E9;

  // ===Font-family===
  --font-ubuntu: 'Ubuntu', sans-serif;
  --font-poppins: 'Poppins', sans-serif;
  --font-stack: var(--font-ubuntu)
}
