.placeholder-upload {
  color: var(--c-low);
}

.content-uploader {
  @apply relative;

  & > .ant-upload-list {
    max-width: 40%;
  }
}

// TODO: refactor styling
.ant-upload-list-item {
  @apply hidden;
}

.files-upload {
  width: 375px;
  li {
    @apply flex items-center justify-between;
    color: var(--secondary-500);

    .anticon {
      width: 14px;
    }

    .files-upload__name {
      @apply text-ellipsis whitespace-nowrap overflow-hidden ml-0.5;
      width: 300px;
    }
  }
}