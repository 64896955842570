.ant-radio-checked {
  &:after {
    border: 1px solid var(--secondary-500);
  }
  .ant-radio-inner {
    border-color: var(--secondary-500);
  }
}

.ant-radio-inner {
  &:after {
    background-color: var(--secondary-500);
  }
}