$loader--width: 250px;
$loader-dot--size: 20px;

.SplashScreen {
  @apply relative bg-white
    w-screen h-screen
    flex justify-center items-center flex-col;

  .loader {
    height: $loader-dot--size;
    width: $loader--width;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    &-dot {
      animation-name: loader;
      animation-timing-function: ease-in-out;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background-color: black;
      position: absolute;
      border: 2px solid white;

      &:first-child {
        background-color: var(--secondary-100);
        animation-delay: 0.5s;
      }
    
      &:nth-child(2) {
        background-color: var(--secondary-300);
        animation-delay: 0.4s;
      }
      
      &:nth-child(3) {
        background-color: var(--secondary-500);
        animation-delay: 0.3s;
      }
      
      &:nth-child(4) {
        background-color: var(--secondary-700);
        animation-delay: 0.2s;
      }
      
      &:nth-child(5) {
        background-color: var(--secondary-800);
        animation-delay: 0.1s;
      }
      
      &:nth-child(6) {
        background-color: var(--secondary-900);
        animation-delay: 0s;
      }
    }
  }

  @keyframes loader {
    15% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(230px);
    }
    65% {
      transform: translateX(230px);
    }
    95% {
      transform: translateX(0);
    }
  }
}