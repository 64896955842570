.kg {
  @apply relative;

  @import './button.scss';
  @import './checkbox.scss';
  @import './input.scss';
  @import './form.scss';
  @import './tag.scss';
  @import './menu.scss';
  @import './table.scss';
  @import './breadcrumbs.scss';
  @import './icon.scss';
  @import './pagination.scss';
  @import './tabs.scss';
  @import './select.scss';
  @import './steps.scss';
  @import './radio.scss';
  @import './modal.scss';
  @import './switch.scss';
  @import './date-picker.scss';

  a {
    color: var(--secondary-500);
  }

}