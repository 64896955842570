.sidebar {
  @apply bg-white
    relative
    h-screen;
  box-shadow: 2px 0px 6px rgba(41, 44, 49, 0.08);
  width: 240px;
  margin-left: -240px;
  transition: .3s;
  z-index: 1001;

  &.hide {
    margin-left: -240px;
  }
  
  &.show {
    margin-left: 0;
  }

  .sidebar-logo {
    @apply py-6 px-4 hover:cursor-pointer;
  }

  .sidebar-trigger {
    @apply absolute;
    right: -10px;
    top: 2.5em;
    border-color: transparent !important;
    color: var(--c-default)!important;
    box-shadow: 0px 2px 6px rgba(41, 44, 49, 0.1);

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      border-color: transparent!important;
      color: var(--c-default)!important;
    }
    
    .anticon {
      font-size: 12px;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--secondary-500);
  }

  .sidebar-menus {
    height: calc(100% - 88px);
    overflow-y: auto;
  }

  .sidebar-account {
    @apply p-4 cursor-pointer mt-4 absolute w-full bottom-0 left-0;
    background-color: var(--neutral-10);

    .sidebar-account-name {
      @apply font-semibold;
      color: var(--c-default);
    }

    .sidebar-account-email {
      color: var(--neutral-90)
    }

    .sidebar-account-name,
    .sidebar-account-email {
      @apply text-sm max-w-[140px] overflow-x-hidden text-ellipsis whitespace-nowrap;
    }
  }
}