.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: var(--neutral-3);
  @apply rounded-t-md;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  @apply bg-white;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary-500);
}

.ant-tabs-top {
  & > .ant-tabs-nav {
    @apply mb-0
  }

  .card {
    @apply rounded-tl-none;
  }
}

.ant-tabs-tab {
  &:hover {
    color: var(--secondary-500);
  }
}