@import './ant-design/message.scss'; /* message is outside of kg class */

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  outline: none;
  &:hover,
  *:focus {
    outline: none;
  }
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  @apply text-xs sm:text-sm md:text-base;
  height: 100%;
  font-family: var(--font-stack) !important;
  overflow-x: hidden;
  box-sizing: border-box;
  color: var(--c-default);
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

.raw-render {
  ul {
    @apply ml-4;
    list-style: disc;
  }
}

button,
input,
select,
textarea {
  margin: 0;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1136px;
  }
}

.kg-content {
  @apply pt-8;
  margin-top: 68px;
  min-height: calc(100vh - 270.5px);

  &.container,
  .container {
    @apply mx-auto;
  }

  a {
    color: inherit;
  }
}

.container {
  @apply px-4 md:px-6 lg:px-6 xl:px-0 mx-auto;
}
