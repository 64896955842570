.ant-breadcrumb {
  color: var(--dark-10);

  li {
    a {
      color: var(--dark-10);
    }
    &:last-of-type {
      color: var(--secondary-500);
      .ant-breadcrumb-link {
        @apply inline-block max-w-[300px] whitespace-nowrap overflow-x-hidden text-ellipsis;
      }
    }
  }
}