.image-preview {
  @apply p-6 flex items-center;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(41, 44, 49, 0.08);
  border-radius: 8px;

  div {
    @apply mr-4;
  }

  img {
    width: 166px;
    height: 76px;
    object-fit: cover;
  }

  .uploader {
    .filename {
      @apply flex items-center font-bold;
      color: #292C31;

      span {
        @apply ml-2 cursor-pointer;
      }
    }

    .edit-button {
      color: #53595F;
      @apply flex items-center text-xs;
  
      span {
        @apply mr-1;
      }
    }
  }
}

.empty {
  @apply p-4;

  text-align: center;
  background: rgba(42, 157, 140, 0.1);
  border: 1px dashed #2A9D8C;
  border-radius: 8px;

  .subtext {
    @apply mt-4;
    color: #313439;
  }

  .add-button {
    @apply flex items-center;
  }
}