.ant-btn {
  @apply rounded-lg
  bg-white
  font-medium text-xs md:text-sm;

  &:not(.ant-btn-dangerous) {
    // border-color: var(--secondary-500);
    color: var(--secondary-500);
  
    &:active {
      border-color: var(--secondary-600);
    }
  
    &:hover,
    &:focus {
      border-color: var(--secondary-400);
    }
  }

  &.ant-btn-lg {
    @apply text-base;
  }

  &.ant-btn-round {
    @apply rounded-full;
  }
  &.ant-btn-circle {
    border-radius: 50%;
  }

  &.ant-btn-primary {
    @apply text-white;
    background: var(--secondary-500);
    border-color: var(--secondary-500);

    &:active {
      background: var(--secondary-600);
      border-color: var(--secondary-600);
    }

    &:hover,
    &:focus {
      background: var(--secondary-400);
      border-color: var(--secondary-400);
    }
    &[disabled] {
      &:active,
      &:hover,
      &:focus {
        background-color: var(--neutral-10);
        border-color: var(--neutral-10);
        color: var(--c-disabled);
      }
    }
  }
  &.ant-btn-primary[disabled],
  &.ant-btn-primary[disabled]:active,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary[disabled]:hover {
    background-color: var(--neutral-10);
    border-color: var(--neutral-10);
    color: var(--c-disabled);
  }

  &.ant-btn-primary.ant-btn-dangerous {
    background: var(--red-50);
    border-color: var(--red-50);

    &:active {
      background: var(--red-20);
      border-color: var(--red-20);
    }

    &:hover,
    &:focus {
      background: var(--red-10);
      border-color: var(--red-10);
    }
  }
  
  .anticon {
    @apply align-middle;
    line-height: 0
  }

  &.ant-btn-link {
    @apply bg-transparent;
    &:hover {
      @apply bg-transparent;
    }
    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
  background-color: var(--neutral-10);
  border-color: var(--neutral-10);
  color: #292C3152;
}

.ant-btn-text {
  color: unset;
  border: unset;
  font-weight: bold;

  &.ant-btn-dangerous {
    color: var(--red-50);
  }
  
  &:hover,
  &:focus {
    background: rgba(0,0,0,.018);
    border-color: unset;
  }
}
