// tag
.ant-tag {
  @apply mb-2 rounded-md font-bold capitalize text-xs text-center;
  border-color: transparent;
  & *{
    border-color: transparent;
  }

  &.ant-tag-green {
    @apply text-white;
    background: var(--green-30);
  }
  &.ant-tag-blue {
    background: var(--blue-1);
    color: var(--blue-2);
  }
  &.ant-tag-pink {
    background: var(--magenta-1);
    color: var(--magenta-2);
  }
  &.ant-tag-red {
    @apply text-white;
    background: var(--red-20);
  }
}