.sidebar {
  .ant-menu {
    @apply pl-4 font-bold text-sm;
    a {
      color: var(--secondary-500);
    }
    &:not(.ant-menu-horizontal) .ant-menu-item-selected {
      @apply font-medium;
      color: var(--secondary-500);
      background-color: transparent;
      .ant-menu-title-content {
        @apply text-white rounded-lg;
        background-color: var(--secondary-500);
        a {
          color: #FFFFFF
        }
      }
    }

    &.ant-menu-sub {
      @apply px-0;

      .ant-menu-title-content {
        @apply ml-6;
      }
    }
  }

  .ant-menu-light {
    .ant-menu-item:hover:not(.ant-menu-item-selected),
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: var(--secondary-500);
    }
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;

    .ant-menu-title-content {
      @apply rounded-lg;
      background: rgba($color: #aad8d1, $alpha: 0.3);
    }
  }

  .ant-menu-sub.ant-menu-inline {
    background: transparent;
    & > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      margin: 0;
      border-left: 2px solid var(--neutral-5);
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 0 !important;
    .ant-menu-title-content {
      padding-left: 16px !important;
    }
  }

  .ant-menu-submenu-selected {
    color: var(--secondary-500);
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: 0;
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: var(--secondary-500);
  }
}
