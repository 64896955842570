.course-information {
  &__upload-thumbnail {
    &.ant-upload {
      &.ant-upload-drag {
        @apply rounded-lg;
        background: rgba($color: #2a9d8c, $alpha: .2);
        border: 1px dashed var(--secondary-500);
      }
      &.ant-upload-drag p.ant-upload-drag-icon .anticon {
        color: var(--secondary-500);
      }
    }
  }
}