.textarea-label {
  @apply font-semibold mb-2;

  span {
    color: #D60B00;
  }
}

.buttons {
  @apply mt-5;

  button {
    @apply mr-4;
  }
}