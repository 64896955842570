// Checkbox
.ant-checkbox {
  @apply rounded-sm;

  .ant-checkbox-input {
    @apply rounded-sm;
  }
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: var(--secondary-500);
    border-color: var(--secondary-500);
  }
}
.ant-checkbox-indeterminate {
  .ant-checkbox-inner:after {
    background-color: var(--secondary-500);
  }
}