.ant-pagination {
  @apply relative;
  .ant-pagination-item {
    a {
      color: var(--c-title);
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      @apply relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .ant-pagination-item-active {
    border-color: var(--blue-2);
    a {
      color: var(--blue-2);
    }
  }
  li.ant-pagination-options {
    @apply hidden
  }
}