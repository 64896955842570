.signin {
  @apply relative;

  &-title {
    @apply font-bold text-2xl mb-6;
    color: var(--c-default);
  }

  &-button {
    @apply mt-6;
  }
}